<div class="hub-d-flex hub-flex-column hub-justify-content-center hub-align-items-center hub-h-100">
  @switch (cause){
    @case(notFoundCause.PAGE){
      <h1 class="hub-text-secondary hub-tt-uppercase">404 {{ cause }} NOT FOUND</h1>
      <img src="../../../assets/images/svg/not-found-page.svg" alt="not found" />
    }
    @case (notFoundCause.ACCOUNT){
      <h1 class="hub-text-secondary hub-tt-uppercase">ACCOUNT NOT FOUND</h1>
      <p class="hub-text-center">
        Oops.. it looks like we couldn’t find any account to sign you in <br />
        Please contact your Admin/Support team <br />
        Or you can Sign Up for a new OL Hub account
      </p>
      <button class="btn btn-primary" (click)="logout()">Sign in as a different user</button>
      <img src="../../../assets/images/svg/dino.svg" alt="not found" />
    }
    @case (notFoundCause.SSO_ONLY){
      <h1 class="hub-text-secondary hub-tt-uppercase">SSO ONLY</h1>
      <p class="hub-text-center">
        The admin of your organization has enabled SSO, <br />
        Username & Password authentication is disabled.
      </p>
      <button class="btn btn-primary" (click)="logout()">Back</button>
      <img src="../../../assets/images/svg/dino.svg" alt="not found" />
    }
  }
</div>
