import { AfterContentInit, Component, Inject, OnInit } from '@angular/core';
import { HubAuthService } from '@core/services/hub-auth/hub-auth.service';
import { KeycloakEventType } from 'keycloak-angular';
import { environment } from '@env';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { concatMap, tap } from 'rxjs/operators';
import { HubEntityActionModalComponent } from '@shared/components/hub-entity-action-modal/hub-entity-action-modal.component';
import { ModalAction } from '@shared/components/hub-entity-action-modal/hub-entity-action-modal';
import { EMPTY, merge } from 'rxjs';
import { DOCUMENT } from '@angular/common';
import { HOTJAR_ORG_ID_PLACEHOLDER } from '@core/hubconfig';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit, AfterContentInit {
  constructor(
    private readonly authService: HubAuthService,
    private readonly modalService: NgbModal,
    @Inject(DOCUMENT) private readonly document: Document,
  ) {}

  ngOnInit(): void {
    this.authService
      .getError$()
      .pipe(
        tap((error) => {
          return (
            (environment.enterprise &&
              ((error as unknown as { type: KeycloakEventType }).type ===
                KeycloakEventType.OnTokenExpired ||
                (error as unknown as { type: KeycloakEventType }).type ===
                  KeycloakEventType.OnAuthError)) ||
            (error as unknown as { message: string }).message === 'Login required'
          );
        }),
        concatMap((hasErrors) => {
          if (!environment.light && hasErrors) {
            const modalRef = this.modalService.open(HubEntityActionModalComponent, {
              centered: true,
            });

            modalRef.componentInstance.steps = [
              {
                actionsButtons: [{ action: ModalAction.apply, label: 'Ok' }],
                title: 'Login Is Required',
                paragraphs: ["Seems like you haven't been here for a while..."],
              },
            ];
            return merge(modalRef.dismissed, modalRef.closed);
          } else {
            return EMPTY;
          }
        }),
      )
      .subscribe(() => {
        this.authService.logout();
      });
  }

  ngAfterContentInit(): void {
    if (environment.HOTJAR_SITE_ID !== HOTJAR_ORG_ID_PLACEHOLDER) {
      ((h, o: Document, t: string, j: string, a?: HTMLHeadElement, r?: HTMLScriptElement) => {
        /* eslint-disable */
        h.hj =
          h.hj ||
          function () {
            (h.hj.q = h.hj.q || []).push(arguments);
          };
        h._hjSettings = { hjid: environment.HOTJAR_SITE_ID, hjsv: '' };
        a = o.getElementsByTagName('head')[0];
        r = o.createElement('script');
        r.async = !!1;
        r.src = t + h._hjSettings.hjid + j + h._hjSettings.hjsv;
        a.appendChild(r);
      })(window, this.document, 'https://static.hotjar.com/c/hotjar-', '.js?sv=');
    }
  }
}
