import { Injectable } from '@angular/core';
import { CanActivate } from '@angular/router';

@Injectable({
  providedIn: 'root',
})
export class ApiKeyGuard implements CanActivate {
  canActivate(): boolean {
    // TODO: check for apikey?
    return true;
  }
}
