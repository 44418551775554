import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { NotFoundCause } from '@core/interfaces/not-found';
import { HubAuthService } from '@core/services/hub-auth/hub-auth.service';

@Component({
  selector: 'app-page-not-found',
  templateUrl: './page-not-found.component.html',
  styleUrls: ['./page-not-found.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PageNotFoundComponent implements OnInit {
  notFoundCause = NotFoundCause;

  cause = '';

  constructor(private route: ActivatedRoute, private hubAuthService: HubAuthService) {}

  ngOnInit() {
    const { cause } = this.route.snapshot.queryParams;
    this.cause = Object.values(NotFoundCause).includes(cause) ? cause : NotFoundCause.PAGE;
  }

  logout() {
    this.hubAuthService.logout();
  }
}
