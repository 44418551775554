import { Injectable } from '@angular/core';
import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Observable } from 'rxjs';
import { mergeMap } from 'rxjs/operators';
import { HubAuthService } from '@core/services/hub-auth/hub-auth.service';

@Injectable()
export class EnterpriseHttpInterceptor implements HttpInterceptor {
  constructor(private auth: HubAuthService) {}

  intercept(httpRequest: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    if (httpRequest.url === '/config') {
      return next.handle(httpRequest);
    }
    return this.auth.getAccessTokenSilently().pipe(
      mergeMap((token) => {
        // Add token to request
        const tokenReq = httpRequest.clone({
          setHeaders: { Authorization: `Bearer ${token}` },
        });
        return next.handle(tokenReq);
      }),
    );
  }
}
