import { KeycloakAuthGuard, KeycloakService } from 'keycloak-angular';
import { ActivatedRouteSnapshot, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Injectable } from '@angular/core';
import { KeycloakProvider } from '@core/providers/keycloack-auth.provider';

@Injectable({
  providedIn: 'root',
})
export class KeycloakAuthGuardImpl extends KeycloakAuthGuard {
  constructor(
    protected override readonly router: Router,
    private keycloakProvider: KeycloakProvider,
    private readonly keycloakService: KeycloakService,
  ) {
    super(router, keycloakService);
  }

  public override canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot,
  ): Promise<boolean | UrlTree> {
    return super.canActivate(route, state);
  }

  public async isAccessAllowed(
    route: ActivatedRouteSnapshot,
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    state: RouterStateSnapshot,
  ): Promise<boolean> {
    // Force the user to log in if currently unauthenticated.
    if (!this.authenticated) {
      await this.keycloakProvider.logout();
    }

    // Get the roles required from the route.
    const requiredRoles = route.data.roles;

    // Allow the user to proceed if no additional roles are required to access the route.
    if (!(requiredRoles instanceof Array) || requiredRoles.length === 0) {
      return true;
    }

    // Allow the user to proceed if all the required roles are present.
    return requiredRoles.every((role) => this.roles.includes(role));
  }
}
