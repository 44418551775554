import { APP_INITIALIZER, NgModule } from '@angular/core';
import { HubAuthService } from '@core/services/hub-auth/hub-auth.service';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { ApiKeyAuthProvider } from '@core/providers/api-key-auth.provider';
import { ProxyHttpInterceptor } from '@core/providers/interceptors/proxy-http-interceptor';
import { ConfigService } from '@core/keycloak/config.service';
import { AppRoutingProxyModule } from './app-routing-proxy.module';

@NgModule({
  declarations: [],
  imports: [AppRoutingProxyModule],
  exports: [AppRoutingProxyModule],
  providers: [
    {
      provide: APP_INITIALIZER,
      multi: true,
      deps: [ConfigService],
      useFactory: (configService: ConfigService) => () => configService.loadConfig(),
    },
    {
      provide: HubAuthService,
      multi: false,
      deps: [ConfigService],
      useFactory: (configService: ConfigService) =>
        new HubAuthService(null, null, new ApiKeyAuthProvider(configService), null),
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: ProxyHttpInterceptor,
      multi: true,
    },
  ],
})
export class AppProxyModule {}
