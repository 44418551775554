import { NgModule } from '@angular/core';
import { HubAuthService } from '@core/services/hub-auth/hub-auth.service';
import { HTTP_INTERCEPTORS, HttpClient } from '@angular/common/http';
import { AppRoutingLightHubModule } from "./app-routing-light-hub.module";
import { MockIamProvider } from "@core/providers/mock-iam-auth.provider";
import { LightHubHttpInterceptor } from "@core/providers/interceptors/light-hub-http-interceptor";

@NgModule({
  declarations: [],
  imports: [AppRoutingLightHubModule],
  exports: [AppRoutingLightHubModule],
  providers: [
    {
      provide: HubAuthService,
      multi: false,
      deps: [HttpClient],
      useFactory: (httpClient: HttpClient) => {
        return new HubAuthService(null, null, null, new MockIamProvider(httpClient))
      },
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: LightHubHttpInterceptor,
      multi: true,
    },
  ],
})
export class AppLightHubModule {
}
