import { Injectable, InjectionToken } from '@angular/core';
import { KeycloakService } from 'keycloak-angular';
import { environment } from '@env';
import { HubEnterpriseConfig } from '@core/keycloak/config.service';

export interface AppConfig {
  isHubOnPrem: boolean;
}

export const APP_CONFIG = new InjectionToken<AppConfig>('app.config', {
  providedIn: 'root',
  factory: (): AppConfig => ({
    isHubOnPrem: environment.enterprise,
  }),
});

@Injectable({
  providedIn: 'root',
})
export class KeycloakInitService {
  constructor(private keycloakService: KeycloakService) {}

  async init(response: HubEnterpriseConfig): Promise<boolean> {
    return this.keycloakService.init({
      config: {
        url: `${response.authDomain}/auth`,
        realm: 'ol-hub',
        clientId: 'hub-spa',
      },
      initOptions: {
        flow: 'implicit',
        onLoad: 'login-required',
        checkLoginIframe: false,
      },
      loadUserProfileAtStartUp: true,
    });
  }
}
