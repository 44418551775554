export {};

declare global {
  interface String {
    toKebabCase(): string;
  }
}

String.prototype.toKebabCase = function (): string {
  return this.replace(/([a-z])([A-Z])/g, '$1-$2').replace(/\s+/g, '-');
};
